
import { defineComponent, PropType, reactive } from "vue";
import { LectureReview } from "@/types/lectures";
import InputBasic from "@/components/console/inputs/InputBasic.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";

export default defineComponent({
  name: "LectureReviewItem",
  components: { CloseIcon, ButtonText, InputBasic },
  props: {
    review: {
      type: Object as PropType<LectureReview>,
      required: true,
    },
  },
  emits: ["updateReview", "deleteReview"],
  setup(props, { emit }) {
    const state = reactive({
      isEditMode: false,
      reviewText: props.review.localization.title,
    });

    const toggleEditMode = () => {
      if (!props.review.isDefault) {
        state.isEditMode = !state.isEditMode;
      }
    };

    const actions = {
      updateReviewText: (value) => {
        state.reviewText = value;
      },
      updateReview: () => {
        if (!props.review.isDefault) {
          emit("updateReview", {
            title: state.reviewText,
            isShow: props.review.isShow,
          });
          toggleEditMode();
        }
      },
      updateReviewShow: () => {
        if (!props.review.isDefault) {
          emit("updateReview", {
            title: props.review.localization.title,
            isShow: !props.review.isShow,
          });
        }
      },
      deleteReview: () => {
        if (!props.review.isDefault) {
          emit("deleteReview");
        }
      },
    };

    return { state, actions, toggleEditMode };
  },
});
