<template>
  <div class="lecture-editor">
    <div ref="toolbar">
      <button class="ql-header" value="2" type="button">H2</button>
      <button class="ql-header" value="3" type="button">B1</button>
      <button class="ql-header" value="4" type="button">B2</button>

      <select class="ql-color">
        <option value="#000000"></option>
        <option value="#ff0000"></option>
        <option value="#ffff00"></option>
        <option value="#00ff00"></option>
        <option value="#3333ff"></option>
        <option value="#ff33ff"></option>
      </select>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button class="ql-bold"></button>
      <button class="ql-underline">underline</button>
      <button class="ql-strike"></button>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button class="ql-align" value=""></button>
      <button class="ql-align" value="center"></button>
      <button class="ql-align" value="right"></button>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button type="button" class="ql-list" value="bullet"></button>
      <button type="button" class="ql-list" value="ordered"></button>
    </div>

    <div
      ref="lectureEditor"
      :style="{
        minHeight: '240px',
      }"
    ></div>
  </div>
</template>

<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import Quill from "quill";

import "quill/dist/quill.bubble.css";
import TextDivider from "@/components/console/dividers/TextDivider.vue";
import Delta from "quill-delta";

export default defineComponent({
  name: "LectureEditor",
  components: { TextDivider },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["updateValue"],
  setup(props, { emit }) {
    const lectureEditor = ref();
    const toolbar = ref();

    let lectureQuill;

    const state = reactive({
      value: props.value ? props.value : "",
    });

    onMounted(async () => {
      let icons = Quill.import("ui/icons");
      icons["header"][
        "2"
      ] = `<img src="/assets/images/svg/ic_header2.svg" alt="Heading2"/>`;
      icons["header"][
        "3"
      ] = `<img src="/assets/images/svg/ic_header3.svg" alt="Heading3"/>`;
      icons["header"][
        "4"
      ] = `<img src="/assets/images/svg/ic_header4.svg" alt="Heading4"/>`;
      icons["bold"] = `<img src="/assets/images/svg/ic_bold.svg" alt="Bold"/>`;
      icons[
        "underline"
      ] = `<img src="/assets/images/svg/ic_underline.svg" alt="Underline"/>`;
      icons[
        "strike"
      ] = `<img src="/assets/images/svg/ic_strike.svg" alt="Strike"/>`;
      icons["align"][
        ""
      ] = `<img src="/assets/images/svg/ic_align_left.svg" alt="Align Left"/>`;
      icons["align"][
        "center"
      ] = `<img src="/assets/images/svg/ic_align_center.svg" alt="Align Center"/>`;
      icons["align"][
        "right"
      ] = `<img src="/assets/images/svg/ic_align_right.svg" alt="Align Right"/>`;
      icons["list"][
        "bullet"
      ] = `<img src="/assets/images/svg/ic_list_bullet.svg" alt="Bullet"/>`;
      icons["list"][
        "ordered"
      ] = `<img src="/assets/images/svg/ic_list_ordered.svg" alt="Order"/>`;

      let EmbedBlock = Quill.import("blots/block/embed");
      class Hr extends EmbedBlock {}
      Hr.blotName = "hr"; //now you can use .ql-hr classname in your toolbar
      Hr.tagName = "hr";
      Quill.register({
        "formats/hr": Hr,
      });

      // 붙여넣기 할때, 맨 위로 튀는 현상 fix
      const Clipboard = Quill.import("modules/clipboard");

      class PlainTextClipboard extends Clipboard {
        onPaste(e) {
          if (e.defaultPrevented || !this.quill.isEnabled()) return;
          let range = this.quill.getSelection();
          let delta = new Delta().retain(range.index);

          if (
            e &&
            e.clipboardData &&
            e.clipboardData.types &&
            e.clipboardData.getData
          ) {
            let text = (e.originalEvent || e).clipboardData.getData(
              "text/plain"
            );
            let cleanedText = this.convert(text);

            // Stop the data from actually being pasted
            e.stopPropagation();
            e.preventDefault();

            // Process cleaned text
            delta = delta.concat(cleanedText).delete(range.length);
            this.quill.updateContents(delta, Quill.sources.USER);
            // range.length contributes to delta.length()
            this.quill.setSelection(
              delta.length() - range.length,
              Quill.sources.SILENT
            );

            return false;
          }
        }
      }

      Quill.register("modules/clipboard", PlainTextClipboard);

      let options = {
        compatibilityMode: false,
        modules: {
          toolbar: {
            container: toolbar.value,
          },
        },
        debug: false, // 'error', 'warn', 'log', or 'info' , false
        placeholder: props.placeholder,
        theme: "snow", // bubble or snow
        bounds: lectureEditor.value,
      };

      lectureQuill = await new Quill(lectureEditor.value, options);

      if (props.value) {
        let convertedDelta = htmlToDelta(props.value);
        lectureQuill.setContents(convertedDelta, "api");
      }

      lectureQuill.on("text-change", update);
    });

    onBeforeUnmount(() => {
      lectureQuill.off("text-change", update);
    });

    const htmlToDelta = (htmlString) => {
      const div = document.createElement("div");
      div.setAttribute("id", "htmlToDelta");
      div.innerHTML = `<div id="quillEditor" style="display:none">${htmlString}</div>`;
      document.body.appendChild(div);
      const quill = new Quill("#quillEditor", {
        theme: "snow",
      });
      const delta = quill.getContents();
      document.getElementById("htmlToDelta").remove();
      return delta;
    };

    const update = (delta) => {
      if (delta) {
        if (lectureQuill.getLength() > 1) {
          state.value = lectureQuill.root.innerHTML;
        } else {
          state.value = "";
        }

        emit("updateValue", state.value);
      }
    };

    return { state, lectureEditor, toolbar };
  },
});
</script>
