
import { defineComponent, PropType, reactive } from "vue";
import { LectureQuestion } from "@/types/lectures";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import swal from "@/helper/swal.js";
import { useStore } from "vuex";
import InputBasic from "@/components/console/inputs/InputBasic.vue";

export default defineComponent({
  name: "LectureQuestionItem",
  components: { InputBasic, ButtonBasic },
  props: {
    question: {
      type: Object as PropType<LectureQuestion>,
      required: true,
    },
    lectureResourceId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  emits: ["updateQuestion"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      isEditMode: false,
      questionTitle: "",
    });

    const actions = {
      deleteQuestion: (question) => {
        store
          .dispatch("lectures/deleteLectureQuestion", {
            lectureResourceId: props.lectureResourceId,
            questionResourceId: question.resourceId,
          })
          .then(() => {
            swal.messageAlert("삭제되었습니다.");
          });
      },
      toggleEditMode: (question) => {
        state.questionTitle = question.localization.title;
        state.isEditMode = true;
      },
      updateQuestionTitle: (value) => {
        state.questionTitle = value;
      },
      updateQuestion: () => {
        emit("updateQuestion", state.questionTitle);
        state.isEditMode = false;
      },
    };

    return { state, actions };
  },
});
