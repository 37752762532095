import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sub-texts2"
}
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_basic = _resolveComponent("input-basic")!
  const _component_button_basic = _resolveComponent("button-basic")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.state.isEditMode)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.question.localization ? _ctx.question.localization.title : ""), 1))
      : _createCommentVNode("", true),
    (_ctx.state.isEditMode)
      ? (_openBlock(), _createBlock(_component_input_basic, {
          key: 1,
          label: "Question",
          "default-value": _ctx.state.questionTitle,
          "is-submit-event": true,
          placeholder: "질문을 작성해보세요",
          onUpdateData: _cache[0] || (_cache[0] = (value) => _ctx.actions.updateQuestionTitle(value)),
          onSubmitEvent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actions.updateQuestion()))
        }, null, 8, ["default-value"]))
      : _createCommentVNode("", true),
    (!_ctx.state.isEditMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_button_basic, {
            class: "edit",
            text: "수정",
            padding: "12px",
            "text-size": "s2",
            onAction: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actions.toggleEditMode(_ctx.question)))
          }),
          _createVNode(_component_button_basic, {
            class: "delete",
            text: "삭제",
            "bg-color": "#FF4646",
            color: "#ffffff",
            padding: "12px",
            "text-size": "s2",
            onAction: _cache[3] || (_cache[3] = ($event: any) => (_ctx.actions.deleteQuestion(_ctx.question)))
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}