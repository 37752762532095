import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3637db91"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "review-item"
}
const _hoisted_2 = { class: "sub-text-s2" }
const _hoisted_3 = { class: "sub-text-s3 text-gray-second" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_button_text = _resolveComponent("button-text")!
  const _component_input_basic = _resolveComponent("input-basic")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.state.isEditMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "info",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleEditMode()))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.review.isDefault ? "default" : ""), 1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.review.localization.title), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.review.voteCount), 1)
          ]),
          _createVNode(_component_button_text, {
            class: "delete-btn",
            padding: "4px",
            text: _ctx.review.isShow ? '숨김' : '보이기',
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actions.updateReviewShow()))
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_close_icon)
            ]),
            _: 1
          }, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.state.isEditMode)
      ? (_openBlock(), _createBlock(_component_input_basic, {
          key: 1,
          "default-value": _ctx.state.reviewText,
          "is-submit-event": true,
          onUpdateData: _cache[2] || (_cache[2] = (value) => _ctx.actions.updateReviewText(value)),
          onSubmitEvent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.actions.updateReview()))
        }, null, 8, ["default-value"]))
      : _createCommentVNode("", true)
  ]))
}