
import InputBasic from "@/components/console/inputs/InputBasic.vue";
import LectureEditor from "./LectureEditor/LectureEditor.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import { computed, defineComponent, onBeforeMount, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  LectureCategories,
  LectureForm,
  LectureTopics,
  UpdateLectureForm,
} from "@/types/lectures";
import PageLoading from "@/components/console/loadings/PageLoading.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import FileStackService from "@/services/FileStackService.js";
import InputTextBox from "@/components/console/inputs/InputTextBox.vue";
import PageHeader from "@/components/console/headers/PageHeader.vue";
import Calendar from "@/components/console/calendar/Calendar.vue";
import { CalendarSchedule } from "@/types/common";
import moment, { Moment } from "moment-timezone";
import swal from "@/helper/swal.js";
import LectureQuestionItem from "@/pages/console/LectureEdit/LectureQuestionItem/LectureQuestionItem.vue";
import { LectureQuestion } from "@/types/lectures";
import LectureReviewItem from "@/pages/console/LectureEdit/LectureReviewItem/LectureReviewItem.vue";

type QuestionForm = {
  resourceId?: string;
  title: string;
};

export default defineComponent({
  name: "LectureEdit",
  components: {
    LectureReviewItem,
    LectureQuestionItem,
    Calendar,
    PageHeader,
    InputTextBox,
    CardBasic,
    PageLoading,
    ButtonBasic,
    LectureEditor,
    InputBasic,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const lectureResourceId = route.path.split("/").slice(-1)[0];

    const state = reactive({
      pageLoading: true,
      scheduleLoading: true,
      lecture: computed(() => {
        return store.getters["lectures/getLecture"];
      }),
      lectureForm: {
        title: "",
        description: "",
        featuredImage: "",
        featuredVideo: "",
        introHost: "",
        featuredVideoThumbnail: "",
        tags: [],
      },
      featuredVideoFile: null,
      featuredImageFile: null,
      featuredVideoThumbnailFile: null,
      schedules: computed(() => {
        return store.getters["lectures/lectureSchedules"];
      }),
      calendarFormatSchedules: [] as CalendarSchedule[],
      lectureQuestions: computed(() => {
        return store.getters["lectures/lectureQuestions"];
      }),
      questionForm: [] as QuestionForm[],
      questionTitle: "",
      coin: 0 as number,
      reviewText: "" as string,
      reviews: computed(() => {
        return store.getters["lectures/lectureReviews"];
      }),
    });

    onBeforeMount(() => {
      store
        .dispatch("lectures/getLecture", {
          resourceId: lectureResourceId,
        })
        .then(() => {
          state.lectureForm.title = state.lecture.title;
          state.lectureForm.description = state.lecture.description;
          state.lectureForm.featuredImage = state.lecture.featuredImage;
          state.lectureForm.featuredVideo = state.lecture.featuredVideo;
          state.lectureForm.featuredVideoThumbnail =
            state.lecture.featuredVideoThumbnail;
          state.lectureForm.introHost = state.lecture.introHost;
          if (state.lecture.sale) {
            state.coin = parseInt(state.lecture.sale.prices.COIN.listPrice);
          }

          state.pageLoading = false;
        });

      getSchedules(moment().startOf("months"), moment().endOf("months"));
      getLectureQuestion();
      getLectureReviews();
    });

    const getSchedules = async (from: Moment, to: Moment) => {
      await store
        .dispatch("lectures/getLectureSchedules", {
          resourceId: lectureResourceId,
          data: {
            startAt: from.format("YYYY-MM-DD HH:mm:ss"),
            endAt: to.format("YYYY-MM-DD HH:mm:ss"),
          },
        })
        .then(() => {
          calenderFormatSchedule();
        });
    };

    const getLectureQuestion = () => {
      state.questionForm.splice(0, state.questionForm.length);

      store
        .dispatch("lectures/getLectureQuestions", {
          lectureResourceId: lectureResourceId,
        })
        .then(() => {
          let questionCount = state.lectureQuestions.length;

          for (let i = 0; i < questionCount; i++) {
            state.questionForm.push({
              title: state.lectureQuestions[i].localization
                ? state.lectureQuestions[i].localization.title
                : "",
              resourceId: state.lectureQuestions[i].resourceId,
            });
          }
        });
    };

    const getLectureReviews = () => {
      store.dispatch("lectures/getLectureReviews", {
        lectureResourceId: lectureResourceId,
      });
    };

    const saveReview = (value) => {
      store
        .dispatch("lectures/postLectureReview", {
          lectureResourceId: lectureResourceId,
          data: {
            title: [value],
          },
        })
        .then(() => {
          state.reviewText = "";
          getLectureReviews();
        });
    };

    const putLectureReview = (
      lectureReviewResourceId: string,
      reviewParams: { title: string; isShow: boolean }
    ) => {
      store
        .dispatch("lectures/putLectureReview", {
          lectureResourceId: lectureResourceId,
          lectureReviewResourceId: lectureReviewResourceId,
          data: reviewParams,
        })
        .then(() => {
          getLectureReviews();
        });
    };

    const calenderFormatSchedule = () => {
      const scheduleCount = state.schedules.length;
      let schedules: CalendarSchedule[] = [];
      for (let i = 0; i < scheduleCount; i++) {
        schedules.push({
          startAt: moment(state.schedules[i].startAt),
          endAt: moment(state.schedules[i].endAt),
        });
      }

      state.calendarFormatSchedules = schedules;
    };

    const saveSchedules = (from: Moment, to: Moment, weekIndex: number) => {
      store
        .dispatch("lectures/postLectureSchedule", {
          resourceId: lectureResourceId,
          data: {
            schedules: [
              {
                startAt: from,
                endAt: to,
                isRepeat: 0,
              },
            ],
          },
        })
        .then(() => {
          let fromDate = moment()
            .add(weekIndex - 3, "days")
            .startOf("date");

          let toDate = moment()
            .add(weekIndex + 3, "days")
            .endOf("date");

          getSchedules(fromDate, toDate);

          swal.messageAlert("스케쥴이 등록되었습니다.");
        });
    };

    const putLecture = (): void => {
      let dataPayload: LectureForm = {
        title: state.lectureForm.title,
        description: state.lectureForm.description,
        introHost: state.lectureForm.introHost,
        tags: state.lectureForm.tags,
        questions: state.questionForm,
        categories: {} as LectureCategories,
      };

      if (state.featuredVideoFile) {
        dataPayload.featuredVideo = state.lectureForm.featuredVideo;
      }
      if (state.featuredImageFile) {
        dataPayload.featuredImage = state.lectureForm.featuredImage;
      }
      if (state.featuredVideoThumbnailFile) {
        dataPayload.featuredVideoThumbnail =
          state.lectureForm.featuredVideoThumbnail;
      }

      let payload: UpdateLectureForm = {
        resourceId: lectureResourceId,
        data: dataPayload,
      };

      store.dispatch("lectures/putLectures", payload);
    };

    const postQuestion = (questionForm) => {
      store
        .dispatch("lectures/postLectureQuestion", {
          lectureResourceId: lectureResourceId,
          data: {
            questions: questionForm,
          },
        })
        .then(() => {
          state.questionTitle = "";
          getLectureQuestion();
          swal.messageAlert("질문이 등록되었습니다.");
        });
    };

    const postLectureCoin = () => {
      let storeEndpoint = !state.lecture.sale
        ? "lectures/postLecturePrice"
        : "lectures/putLecturePrice";

      store
        .dispatch(storeEndpoint, {
          lectureResourceId: lectureResourceId,
          data: {
            coin: state.coin,
          },
        })
        .then(() => {
          state.questionTitle = "";
          swal.messageAlert("코인 수 변경 완료!");
        });
    };

    const deleteLectureSchedule = async (timeGrid, weekIndex: number) => {
      let schedule = state.schedules.find((item) => {
        return (
          moment(item.startAt).format("YYYY-MM-DD HH:mm") ===
          moment(timeGrid.startAt).format("YYYY-MM-DD HH:mm")
        );
      });

      await store
        .dispatch("lectures/deleteLectureSchedule", {
          lectureResourceId: lectureResourceId,
          lectureScheduleResourceId: schedule.resourceId,
        })
        .then(() => {
          let fromDate = moment()
            .add(weekIndex - 3, "days")
            .startOf("date");

          let toDate = moment()
            .add(weekIndex + 3, "days")
            .endOf("date");

          getSchedules(fromDate, toDate);
          calenderFormatSchedule();
          swal.messageAlert("스케쥴이 닫혔습니다.");
        });
    };

    const cancelReservedSchedule = async (timeGrid, weekIndex: number) => {
      let schedule = state.schedules.find((item) => {
        return (
          moment(item.startAt).format("YYYY-MM-DD HH:mm") ===
          moment(timeGrid.startAt).format("YYYY-MM-DD HH:mm")
        );
      });

      await store
        .dispatch("lectures/cancelReservedSchedule", {
          lectureResourceId: lectureResourceId,
          lectureScheduleResourceId: schedule.resourceId,
        })
        .then(() => {
          let fromDate = moment()
            .add(weekIndex - 3, "days")
            .startOf("date");

          let toDate = moment()
            .add(weekIndex + 3, "days")
            .endOf("date");

          getSchedules(fromDate, toDate);
          calenderFormatSchedule();
          swal.messageAlert("예약이 취소되었습니다.");
        });
    };

    const actions = {
      updateLecture: () => {
        putLecture();
      },
      updateLectureDescription: (value) => {
        state.lectureForm.description = value;
      },
      updateLectureTitle: (value) => {
        state.lectureForm.title = value;
      },
      openImageFileStack: () => {
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop = true;

        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          state.lectureForm.featuredImage = fileMetaData.url;
          state.featuredImageFile = fileMetaData;
        };

        fileStack.open(fileStack.options);
      },
      openVideoFileStack: () => {
        const fileStack = new FileStackService();
        fileStack.options.accept = ["video/ogg", "video/mpeg", "video/webm"];

        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          state.lectureForm.featuredVideo = fileMetaData.url;
          state.featuredVideoFile = fileMetaData;
        };

        fileStack.open(fileStack.options);
      },
      openVideoThumbnailFileStack: () => {
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop = true;

        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          state.lectureForm.featuredVideoThumbnail = fileMetaData.url;
          state.featuredVideoThumbnailFile = fileMetaData;
        };

        fileStack.open(fileStack.options);
      },
      goToLectures: () => {
        router.push({ name: "console.lectures" });
      },
      updateQuestionTitle: (value) => {
        state.questionTitle = value;
      },
      updateQuestion: (question: LectureQuestion, value: string): void => {
        let questionIndex = state.questionForm.findIndex((item) => {
          return item.resourceId === question.resourceId;
        });
        state.questionForm[questionIndex].title = value;
        postQuestion(state.questionForm);
      },
      createQuestion: () => {
        if (!state.questionTitle) {
          return;
        }
        state.questionForm.push({ title: state.questionTitle });
        postQuestion(state.questionForm);
      },
      updateCoin: (value) => {
        state.coin = parseInt(value);
      },
      updateReviewData: (value: string) => {
        state.reviewText = value;
      },
    };

    return {
      state,
      actions,
      getSchedules,
      saveSchedules,
      postQuestion,
      postLectureCoin,
      deleteLectureSchedule,
      cancelReservedSchedule,
      saveReview,
      putLectureReview,
      lectureResourceId,
    };
  },
});
